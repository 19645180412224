var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Footer" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeFooter) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("code", [_vm._v("<b-sidebar>")]),
        _c("span", [_vm._v(" provides a ")]),
        _c("code", [_vm._v("footer")]),
        _c("span", [
          _vm._v(
            " slot (optionally scoped), to allow you to provide content that appears at the bottom of the sidebar. The "
          )
        ]),
        _c("code", [_vm._v("footer")]),
        _c("span", [_vm._v(" slot is scoped, which includes a")]),
        _vm._v(" "),
        _c("code", [_vm._v("hide()")]),
        _c("span", [_vm._v(" method that can be used to close the sidebar.")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.sidebar-footer",
                  modifiers: { "sidebar-footer": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Toggle Sidebar ")]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "sidebar-footer",
                "aria-label": "Sidebar with custom footer",
                shadow: "",
                backdrop: "",
                "bg-variant": "white"
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function() {
                    return [_c("sidebar-content-footer")]
                  },
                  proxy: true
                }
              ])
            },
            [_c("sidebar-content")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }