var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Sidebar Variant" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVariant) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use the props ")]),
        _c("code", [_vm._v("bg-variant")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("text-variant")]),
        _c("span", [
          _vm._v(
            " to control the theme color variant of the background and text, respectively. Alternatively, you can apply styles or classes to specify the background and text colors. "
          )
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.sidebar-variant",
                  modifiers: { "sidebar-variant": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Toggle Sidebar ")]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "sidebar-variant",
                "bg-variant": "dark",
                "text-variant": "light",
                shadow: "",
                backdrop: ""
              }
            },
            [_c("sidebar-content")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }