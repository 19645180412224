var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Sidebar Placement" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePlacement) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "By default the sidebar will be placed on the left side fo the viewport. Set the "
          )
        ]),
        _c("code", [_vm._v("right")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("true")]),
        _c("span", [
          _vm._v(
            " to have the sidebar appear on the right side of the viewport."
          )
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.sidebar-right",
                  modifiers: { "sidebar-right": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Toggle Sidebar ")]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "sidebar-right",
                "bg-variant": "white",
                right: "",
                backdrop: "",
                shadow: ""
              }
            },
            [_c("sidebar-content")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }