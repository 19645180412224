var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-2 border-top text-danger cursor-pointer" },
    [
      _c("feather-icon", { attrs: { icon: "LogOutIcon" } }),
      _c("span", { staticClass: "ml-1" }, [_vm._v("Logout")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }