var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Backdrop" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBackdrop) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Sidebar component don't have backdrop by default. You can add it using "
          )
        ]),
        _c("code", [_vm._v("backdrop")]),
        _c("span", [
          _vm._v(" prop. Below demo shows sidebar "),
          _c("strong", [_vm._v("without")]),
          _vm._v(" backdrop")
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                },
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.sidebar-backdrop",
                  modifiers: { "sidebar-backdrop": true }
                }
              ],
              attrs: { variant: "outline-primary" }
            },
            [_vm._v(" Toggle Sidebar Without Backdrop ")]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "sidebar-backdrop",
                "bg-variant": "white",
                shadow: ""
              }
            },
            [_c("sidebar-content")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }